import React, { Component } from 'react';
import { Table, Button, Modal, Form, Row, Col, Card, Select, message, Divider, Input } from 'antd';
import { PlusOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import FactureService from '../../services/FactureService';
import { hasActionByModule } from 'utilities/modules';
import Cookies from 'js-cookie';
import { DEFAULT_FORM_LAYOUT } from 'utilities/ui';

const { Option } = Select;

class Facture extends Component {
  constructor(props) {
    super(props);
    const nbRowPreference = Cookies.get('nbRow') !== undefined ? Cookies.get('nbRow') : 10;
    this.state = {
      rawData: [],
      data: [],
      loading: false,
      visible: false,
      editingRecord: null,
      societeOptions: [],
      agenceOptions: [],
      codeTierOptions: [],
      groupOptions: [],
      nbRow: nbRowPreference,
      searchText: '',
      searchedColumn: '',
    };
    this.modalFormRef = React.createRef();
  }

  componentDidMount() {
    this.fetchData();
    this.fetchOptions();
    this.fetchCodeTierOptions();
    this.fetchGroupOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.visible && !this.state.visible) {
      this.resetModalFormAndState();
    }
  }

  fetchData = (params = {}) => {
    this.setState({ loading: true });
    message.loading('Chargement des données en cours...', 0.5);
    FactureService.fetchFactureDetails(params)
      .then(response => {
        this.setState({ rawData: response.data, data: response.data, loading: false });
        message.success('Chargement des données terminé');
      })
      .catch(error => {
        this.setState({ loading: false });
        message.error('Une erreur est survenue lors du chargement des données.');
      });
  };

  fetchOptions = () => {
    FactureService.fetchSocieteOptions()
      .then(response => {
        const sortedSocieteOptions = response.data.sort((a, b) => a.localeCompare(b));
        this.setState({ societeOptions: sortedSocieteOptions });
      })
      .catch(error => {
        console.error('Error fetching societe options: ', error);
      });

    FactureService.fetchAgenceOptions()
      .then(response => {
        const sortedAgenceOptions = response.data.sort((a, b) => a.localeCompare(b));
        this.setState({ agenceOptions: sortedAgenceOptions });
      })
      .catch(error => {
        console.error('Error fetching agence options: ', error);
      });
  };

  fetchCodeTierOptions = () => {
    FactureService.fetchCodeTierOptions()
      .then(response => {
        const sortedCodeTierOptions = response.data.sort((a, b) => a.localeCompare(b));
        this.setState({ codeTierOptions: sortedCodeTierOptions });
      })
      .catch(error => {
        console.error('Error fetching code tiers options: ', error);
      });
  };

  fetchGroupOptions = () => {
    FactureService.fetchGroupFormats()
      .then(response => {
        const groupOptions = Array.isArray(response.data) ? response.data : [];
        this.setState({ groupOptions });
      })
      .catch(error => {
        console.error('Error fetching group options: ', error);
      });
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    }, () => {
      this.setState({
        data: this.state.rawData.filter(item =>
          item[dataIndex]
            .toString()
            .toLowerCase()
            .includes(this.state.searchText.toLowerCase())
        ),
      });
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '', data: this.state.rawData });
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Rechercher ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Rechercher
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Réinitialiser
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  });

  showModal = () => {
    this.setState({ visible: true, editingRecord: null });
  };

  showEditModal = async (record) => {
    await this.setState({ visible: true, editingRecord: record });
    if (this.modalFormRef.current) {
      const group = this.state.groupOptions.find(group => group.name === record.nomGroupe);
      const idGroup = group ? group.id : null;
      this.modalFormRef.current.setFieldsValue({
        societe: record.societe,
        agence: record.agence,
        codeTiers: record.codeTiers,
        siretEdi: record.siretEdi,
        idGroup: idGroup,
      });
    }
  };

  resetModalFormAndState = () => {
    if (this.modalFormRef.current) {
      this.modalFormRef.current.setFieldsValue({
        societe: '',
        agence: '',
        codeTiers: '',
        siretEdi: '',
        idGroup: '',
      });
    }
    this.setState({ visible: false, editingRecord: null });
  };

  handleCancel = () => {
    this.resetModalFormAndState();
  };

  handleAdd = (values) => {
    const data = {
      societe: values.societe,
      agence: values.agence,
      codeTiers: values.codeTiers,
      siretEdi: values.siretEdi,
      idGroup: values.idGroup,
    };

    if (this.state.editingRecord) {
      FactureService.updateFacture(this.state.editingRecord.id, data)
        .then(response => {
          this.fetchData();
          this.resetModalFormAndState();
        })
        .catch(error => {
          message.error('Close unicité compromise ou problème serveur');
        });
    } else {
      FactureService.addFacture(data)
        .then(response => {
          this.fetchData();
          this.resetModalFormAndState();
        })
        .catch(error => {
          message.error('Close unicité compromise ou problème serveur');
        });
    }
  };

  handleNbRowChange = (value) => {
    Cookies.set('nbRow', value);
    this.setState({ nbRow: value });
  };

  render() {
    const columns = [
      {
        title: 'Société',
        dataIndex: 'societe',
        key: 'societe',
        ...this.getColumnSearchProps('societe'),
        sorter: (a, b) => a.societe.localeCompare(b.societe),
      },
      {
        title: 'Agence',
        dataIndex: 'agence',
        key: 'agence',
        ...this.getColumnSearchProps('agence'),
        sorter: (a, b) => a.agence.localeCompare(b.agence),
      },
      {
        title: 'Code Tiers',
        dataIndex: 'codeTiers',
        key: 'codeTiers',
        ...this.getColumnSearchProps('codeTiers'),
        sorter: (a, b) => a.codeTiers.localeCompare(b.codeTiers),
      },
      {
        title: 'Siret EDI',
        dataIndex: 'siretEdi',
        key: 'siretEdi',
        ...this.getColumnSearchProps('siretEdi'),
        sorter: (a, b) => a.siretEdi.localeCompare(b.siretEdi),
      },
      {
        title: 'Groupe',
        dataIndex: 'nomGroupe',
        key: 'nomGroupe',
        ...this.getColumnSearchProps('nomGroupe'),
        sorter: (a, b) => a.nomGroupe.localeCompare(b.nomGroupe),
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <Button icon={<EditOutlined />} onClick={() => this.showEditModal(record)}>Editer</Button>
        ),
      },
    ];

    return (
      <>
        {hasActionByModule('Facture', 'Accéder') && (
          <div>
            <Card style={{ marginBottom: 16 }}>
              <Row gutter={{ xs: 1, sm: 16, md: 24, lg: 32 }} align="middle" justify="space-between">
                <Col>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: 8 }}>Nombre de lignes:</div>
                    <Select
                      style={{ width: '80px' }}
                      defaultValue={this.state.nbRow}
                      onChange={this.handleNbRowChange}
                    >
                      <Option value="5">5</Option>
                      <Option value="10">10</Option>
                      <Option value="25">25</Option>
                      <Option value="50">50</Option>
                    </Select>
                  </div>
                </Col>
                {hasActionByModule('Facture', 'Créer') && (
                  <Col>
                    <Button type="primary" icon={<PlusOutlined />} onClick={this.showModal}>
                      Ajouter un confrère
                    </Button>
                  </Col>
                )}
              </Row>
              <Divider />
              <Table
                columns={columns}
                dataSource={this.state.data}
                loading={this.state.loading}
                rowKey="id"
                pagination={{ pageSize: parseInt(this.state.nbRow, 10) }}
              />
            </Card>

            <Modal
              title={this.state.editingRecord ? "Editer le confrère" : "Ajouter un nouveau confrère"}
              visible={this.state.visible}
              onCancel={this.handleCancel}
              footer={null}
            >
              <Form
                layout="vertical"
                ref={this.modalFormRef}
                onFinish={this.handleAdd}
                initialValues={this.state.editingRecord || {}}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Société"
                      name="societe"
                      rules={[{ required: true, message: 'Veuillez entrer la Société' }]}
                    >
                      <Input disabled={this.state.editingRecord !== null} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Agence"
                      name="agence"
                      rules={[{ required: true, message: 'Veuillez entrer l\'Agence' }]}
                    >
                      <Input disabled={this.state.editingRecord !== null} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Code Tiers"
                      name="codeTiers"
                      rules={[{ required: true, message: 'Veuillez entrer le Code Tiers' }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Siret EDI"
                      name="siretEdi"
                      rules={[{ required: true, message: 'Veuillez entrer le Siret EDI' }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Nom du Groupe"
                      name="idGroup"
                      rules={[{ required: true, message: 'Veuillez sélectionner le Nom du Groupe' }]}
                    >
                      <Select placeholder="Sélectionner Nom du Groupe" style={{ width: '100%' }} allowClear>
                        {Array.isArray(this.state.groupOptions) && this.state.groupOptions.map(group => (
                          <Option key={group.id} value={group.id}>
                            {group.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                {hasActionByModule('Facture', 'Créer') && (
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      {this.state.editingRecord ? "Editer" : "Ajouter"}
                    </Button>
                  </Form.Item>
                )}
              </Form>
            </Modal>
          </div>
        )}
      </>
    );
  }
}

export default Facture;
