import 'antd/dist/antd.css'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import React from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment-timezone'
import store from './store'
import NoMatch from 'pages/404'
import Login from 'pages/login/login'
import Index from 'pages/home/home'
import RechercheBord from 'pages/echanges/rechercheBordereaux'
import RechercheRecep from 'pages/echanges/rechercheRecep'
import Parametre from 'pages/parametres/parametre'
import { Statistique } from 'pages/statistique/statistique'
import Facture from 'pages/facture/facture'
import ModifyPassword from 'pages/modifyPassword'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import 'antd/dist/antd.css'
import { App } from './App'
import { Retention } from 'pages/retention/retention'
import { Documentation } from 'pages/support/Documentation'
import { Ticketing } from 'pages/support/ticketing'
import Profile from 'pages/profile/profile'
import { ConfigProvider } from 'antd'
import { I18nextProvider } from 'react-i18next'
import i18n from './utilities/i18n'
import frFR from 'antd/lib/locale/fr_FR'

moment.locale('fr')
let islogged = localStorage.getItem('isAuthenticated')
let isActivated = localStorage.getItem('isActive')
let isActive = false
if (isActivated === 'true') {
    isActive = true
}
console.error = console.warn = console.trace = console.debug = console.info = () => {}

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <ConfigProvider locale={frFR}>
            <Provider store={store}>
                <BrowserRouter>
                    <Switch>
                        {!islogged && <Route path="/Login" component={Login}></Route>}
                        {!isActive && <Route path="/ModifyPassword" component={ModifyPassword}></Route>}
                        <App path={'/Parametre'} component={Parametre} />
                        <App path={'/RechercheBord'} component={RechercheBord} />
                        <App path={'/RechercheRecep'} component={RechercheRecep} />
                        <App path={'/Statistique'} component={Statistique}></App>
                        <App exact path="/" component={Index} />
                        <App path={'/Documentation'} component={Documentation} />
                        <App path={'/Profile'} component={Profile} />
                        <App path={'/Retention'} component={Retention} />
                        <App path={'/Ticketing'} component={Ticketing} />
                        <App path={'/Facture'} component={Facture} />
                        <Route component={NoMatch} />
                    </Switch>
                </BrowserRouter>
            </Provider>
        </ConfigProvider>
    </I18nextProvider>,
    document.getElementById('root')
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
