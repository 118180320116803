
import { SettingOutlined, SwapOutlined, BarChartOutlined, CommentOutlined, UserOutlined, FileTextOutlined, ShareAltOutlined,FileDoneOutlined } from '@ant-design/icons'
import React from 'react'

export const ModulesEnum = {
    RechercheRecep: 'Recherche récépissés',
    RechercheBord: 'Recherche bordereaux',
    Volumetrie: 'Volumétrie',
    Echange: 'Echange(s)',
    Document: 'Document(s)',
    Statistique: 'Statistique(s)',
    Support: 'Support',
    Parametre: 'Paramètre(s)',
    Item: 'item',
    Documentation: 'Documentation',
    MessagesErreur: 'Message(s) en erreur',
    Ticketing: 'Ticketing',
    KPI: 'K.P.I.',
    Utilisateur: 'Utilisateur(s)',
    Role: 'Rôle(s)',
    Retention: 'Retention',
    Facture: 'Facture',
}

export const getModuleEnumByLabel = (label) => {
    switch (label) {
        case 'Recherche récépissés':
            return 'RechercheRecep'
        case 'Recherche bordereaux':
            return 'RechercheBord'
        case 'Volumétrie':
            return 'Volumetrie'
        case 'Echange(s)':
            return 'Echange'
        case 'Document(s)':
            return 'Document'
        case 'Statistique(s)':
            return 'Statistique'
        case 'Support':
            return 'Support'
        case 'Paramètre(s)':
            return 'Parametre'
        case 'item':
            return 'Item'
        case 'Documentation':
            return 'Documentation'
        case 'Message(s) en erreur':
            return 'MessagesErreur'
        case 'Ticketing':
            return 'Ticketing'
        case 'K.P.I.':
            return 'KPI'
        case 'Utilisateur(s)':
            return 'Utilisateur'
        case 'Rôle(s)':
            return 'Role'
        case 'Retention':
            return 'Retention'
            case 'Facture':
                return 'Facture'      
        default:
            return null
    }
}

export const getModuleRow = (row, label) => {
    switch (label) {
        case 'RechercheRecep':
            return row.RechercheRecep
        case 'RechercheBord':
            return row.RechercheBord
        case 'Volumetrie':
            return row.Volumetrie
        case 'Echange':
            return row.Echange
        case 'Document':
            return row.Document
        case 'Statistique':
            return row.Statistique
        case 'Support':
            return row.Support
        case 'Parametre':
            return row.Parametre
        case 'Item':
            return row.item
        case 'Documentation':
            return row.Documentation
        case 'MessagesErreur':
            return row.MessagesErreur
        case 'Ticketing':
            return row.Ticketing
        case 'KPI':
            return row.KPI
        case 'Utilisateur':
            return row.Utilisateur
        case 'Role':
            return row.Role
        case 'Retention':
            return row.Retention
            case 'Facture':
                return row.Facture    
        default:
            return null
    }
}

export const getRoleAuthorities = (row, label) => {
    switch (label) {
        case 'RechercheRecep':
            return row.roleAuthorities.RechercheRecep
        case 'RechercheBord':
            return row.roleAuthorities.RechercheBord
        case 'Volumetrie':
            return row.roleAuthorities.Volumetrie
        case 'Echange':
            return row.roleAuthorities.Echange
        case 'Document':
            return row.roleAuthorities.Document
        case 'Statistique':
            return row.roleAuthorities.Statistique
        case 'Support':
            return row.roleAuthorities.Support
        case 'Parametre':
            return row.roleAuthorities.Parametre
        case 'Item':
            return row.roleAuthorities.item
        case 'Documentation':
            return row.roleAuthorities.Documentation
        case 'MessagesErreur':
            return row.roleAuthorities.MessagesErreur
        case 'Ticketing':
            return row.roleAuthorities.Ticketing
        case 'KPI':
            return row.roleAuthorities.KPI
        case 'Utilisateur':
            return row.roleAuthorities.Utilisateur
        case 'Role':
            return row.roleAuthorities.Role
        case 'Retention':
            return row.roleAuthorities.Retention
        case 'Facture':
                    return row.roleAuthorities.Facture
        default:
            return null
    }
}
export const getAllowedAuthorities = (row, label) => {
    switch (label) {
        case 'RechercheRecep':
            return row.allowedAuthorities.RechercheRecep
        case 'RechercheBord':
            return row.allowedAuthorities.RechercheBord
        case 'Volumetrie':
            return row.allowedAuthorities.Volumetrie
        case 'Echange':
            return row.allowedAuthorities.Echange
        case 'Document':
            return row.allowedAuthorities.Document
        case 'Statistique':
            return row.allowedAuthorities.Statistique
        case 'Support':
            return row.allowedAuthorities.Support
        case 'Parametre':
            return row.allowedAuthorities.Parametre
        case 'Item':
            return row.allowedAuthorities.item
        case 'Documentation':
            return row.allowedAuthorities.Documentation
        case 'MessagesErreur':
            return row.allowedAuthorities.MessagesErreur
        case 'Ticketing':
            return row.allowedAuthorities.Ticketing
        case 'KPI':
            return row.allowedAuthorities.KPI
        case 'Utilisateur':
            return row.allowedAuthorities.Utilisateur
        case 'Role':
            return row.allowedAuthorities.Role
        case 'Retention':
            return row.allowedAuthorities.Retention
        case 'Facture':
                return row.allowedAuthorities.Facture    
        default:
            return null
    }
}

export const getModuleIcon = (label) => {
    switch (label) {
        case 'Echange(s)':
            return <SwapOutlined />
        case 'Statistique(s)':
            return <BarChartOutlined />
        case 'Paramètre(s)':
            return <SettingOutlined />
        case 'Utilisateur(s)':
            return <UserOutlined />
        case 'Retention':
            return <ShareAltOutlined />
        case 'Document(s)':
            return <FileTextOutlined />
        case 'Support':
            return <CommentOutlined />
            case 'Facture':
                return <FileDoneOutlined />    
        default:
            return null
    }
}


